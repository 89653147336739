import { styled } from "@mui/material";
import { Grid, Paper, Stack, Typography } from "../../../UIComponents";

import CollapsibleTable from "./CollapsibleTable";
import AssignCertificationModal from "./AssignCertificateModal";
import React, { createContext, useState } from "react";
import FilterPopover from "./FilterPopover";
import { useDispatch } from "react-redux";
import { fetchCertificationDashboard } from "../../../redux/reducers/certificationDashboard";
import ExportButton from "./ExportButton";

export const ModalContext = createContext();

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: "6px",
  padding: "16px",
}));

const Dashboard = () => {
  const dispatch = useDispatch();

  const handleFilterChange = (filters) => {
    const { certification_id, platform_id, status_filter, expiry_filter } =
      filters;
    dispatch(
      fetchCertificationDashboard({
        certification_id,
        platform_id,
        status_filter,
        expiry_filter,
      })
    );
  };

  const [assignCertModal, setAssignCertModal] = useState({
    isOpen: false,
    userId: undefined,
  });

  const handleAssignCertModalOpen = (memberId) => {
    setAssignCertModal({
      isOpen: true,
      userId: memberId,
    });
  };

  const handleAssignCertModalClose = () =>
    setAssignCertModal({
      isOpen: false,
      userId: undefined,
    });

  return (
    <>
      <ModalContext.Provider value={{ handleAssignCertModalOpen }}>
        <Grid container>
          <Grid item xs={12}>
            <StyledPaper direction={"column"} spacing={2} mt={2}>
              <Grid container gap={2} justifyContent="space-between">
                <Grid item xs={3}>
                  <Typography fontSize="26px">
                    Certification Dashboard
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Stack justifyContent="flex-end" direction="row" gap={2}>
                    <ExportButton />
                    <FilterPopover handleFilterChange={handleFilterChange} />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <CollapsibleTable />
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>
        </Grid>
        <AssignCertificationModal
          isOpen={assignCertModal.isOpen}
          onClose={handleAssignCertModalClose}
          userId={assignCertModal.userId}
        />
      </ModalContext.Provider>
    </>
  );
};

export default Dashboard;
