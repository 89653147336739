import React from "react";
import { useSelector } from "react-redux";
import { selectAllCertifications } from "../../../redux/reducers/certificationDashboard.js";
import Button from "../../../UIComponents/DesignSystem/Button.js";

const ExportButton = () => {
  const certifications = useSelector(selectAllCertifications);

  const handleExport = () => {
    if (!certifications || certifications.length === 0) {
      console.warn("No data available to export.");
      return;
    }

    const headers = [
      "id",
      "employee_name",
      "manager_name",
      "email",
      "certification_id",
      "certification_name",
      "status",
      "expiry_date",
      "days_to_expire",
      "counts_towards_partner_count",
    ];

    const rows = [];
    certifications.forEach((certification) => {
      certification.certificates.forEach((cert) => {
        rows.push([
          certification.id,
          certification.employee_name,
          certification.manager_name,
          certification.email,
          cert.certification_id,
          cert.certification_name,
          cert.status,
          cert.expiry_date,
          cert.days_to_expire,
          cert.counts_towards_partner_count,
        ]);
      });
    });

    const csvContent = [headers.join(",")]
      .concat(rows.map((row) => row.map((item) => `"${item}"`).join(",")))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `user_certifications_report_${new Date().toISOString()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button variant="contained" color="primaryLight" onClick={handleExport}>
      Download CSV
    </Button>
  );
};

export default ExportButton;
